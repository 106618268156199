import React from 'react';
import PropTypes from 'prop-types';
import { DialogCloseButton } from './StyledComponents';
import { X } from 'react-feather';

const CloseButton = ({onClick}) => {
  return (
    <DialogCloseButton onClick={onClick}>
      <X size={32}/>
    </DialogCloseButton>
  )
}

CloseButton.propTypes = {
  onClick: PropTypes.func,
}

export default CloseButton;
