export const D = Object.freeze({
  general: {
    brandingAlt: 'Valoremos El Trabajo Artesanal',
  },
  landing: {
    button: 'Disfruta los documentales',
    socialFollow: 'Síguenos',
    screenReaderMessage: 'Bienvenido a Valoremos el trabajo artesanal'
  },
  home: {
    buttonLabel: 'Sinopsis de documental',
    documentals: 'Documentales',
    aboutUs: 'Acerca De',
    sponsors: "Patrocinadores"
  },
  video: {
    new: 'Estreno'
  }
});
