import React from "react";
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby";
import StaticImgComponent from "./StaticImg";
// Functional components
import CloseButton from "./CloseButton";
import { Row, Grid, Col } from "react-flexbox-grid";
// Styled Components
import { DialogWrapper, Subtitle, SponsorsContainer } from "./StyledComponents";
import { D } from "../constants/dictionary";

const AboutUs = ({ showAboutUs, aboutUs }) => {
  /** Getting landing data with staticQuery */
  const LOGO_DATA = useStaticQuery(
    graphql`
      query {
        landingYaml {
          identityImageDark {
            image
          }
        }
        homeYaml {
          sponsorsBgMov {
            image
          }
          sponsorsBgDesk {
            image
          }
          gallery {
            image
          }
        }
      }
    `
  );

  const { identityImageDark } = LOGO_DATA.landingYaml;
  const { sponsorsBgMov, sponsorsBgDesk, gallery } = LOGO_DATA.homeYaml;
  return (
    <DialogWrapper
      className={aboutUs && "active"}
      backgroundMob={sponsorsBgMov.image}
      backgroundDesk={sponsorsBgDesk.image}
    >
      <Grid>
        <CloseButton onClick={showAboutUs} />
        <Row center="xs">
          <StaticImgComponent
            alt={D.general.brandingAlt}
            maxWidth={320}
            src={identityImageDark.image}
          />
        </Row>
        <Row center="xs" style={{marginBottom: "1rem",}}>
          <Subtitle color="var(--c300_light)" dash={true}>{D.home.sponsors}</Subtitle>
        </Row>
        <Row center="xs" style={{marginBottom: "1rem",}}>
          <Col xs={12}>
            <SponsorsContainer>
              {gallery.map ((el, i) => {
                return (
                  <Col key={i} xs={4}><StaticImgComponent
                    alt={D.home.sponsors}
                    maxWidth={200}
                    src={el.image}
                  /></Col>
                )
              })}
            </SponsorsContainer>
          </Col>
        </Row>
      </Grid>
    </DialogWrapper>
  );
};

AboutUs.propTypes = {
  videoTitle: PropTypes.string,
  videoSummary: PropTypes.string,
  sinopsis: PropTypes.string,
  showSinopsis: PropTypes.func
}

export default AboutUs;
