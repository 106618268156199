import React, { Fragment } from "react";
import { withOrientationChange } from 'react-device-detect'
import { PropTypes } from "prop-types";
/** Importing functional components */
import SEO from './../components/SEO'
/** Importing styled components */
import { RootLayout, Orientation, Paragraph } from "../components/StyledComponents";



let DefaultPage = ({ socialImg, pageTitle, children, isPortrait }) => {
  return (
    <Fragment>
      <SEO socialImg={socialImg} title={pageTitle ? pageTitle : "Valoremos El Trabajo Artesanal"}></SEO>
      {isPortrait && <Orientation>
        <Paragraph color="var(--c400)">Rota tu dispositivo por favor  &#128512;</Paragraph>
      </Orientation>}
      <RootLayout>
        {children}
      </RootLayout>
    </Fragment>
  );
};

DefaultPage.propTypes = {
  pageTitle: PropTypes.string,
  socialImg: PropTypes.string,
  children: PropTypes.any,
  isPortrait: PropTypes.bool
};

DefaultPage = withOrientationChange(DefaultPage)


export {DefaultPage};
