import React from 'react';
import PropTypes from 'prop-types';
// Icons
import { PlayCircle, PauseCircle, VolumeX, Volume2, Maximize } from 'react-feather';
// Styled Components
import { VideoControls, VideoControlsButton, VideoControlsSeek, VideoControlsWrapper, ButtonSinopsis, ParagraphSmall } from './StyledComponents';
import { D } from '../constants/dictionary';


const VideoPlayerControls = ({
  handlePlayPause,
  playing,
  handleToggleMuted,
  muted,
  handleClickFullscreen,
  played,
  loaded,
  showSinopsis
}) => {
  return (
    <VideoControlsWrapper>
      <ButtonSinopsis onClick={showSinopsis}><ParagraphSmall color="var(--white)">{D.home.buttonLabel}</ParagraphSmall></ButtonSinopsis>
      <VideoControls>
        <VideoControlsButton onClick={handlePlayPause}>{playing ? <PauseCircle /> : <PlayCircle />}</VideoControlsButton>
        <VideoControlsButton onClick={handleToggleMuted}>{muted ? <VolumeX /> : <Volume2 />}</VideoControlsButton>
        <VideoControlsButton onClick={handleClickFullscreen}><Maximize /></VideoControlsButton>
        <VideoControlsSeek>
          <progress id="loaded" max={1} value={loaded} />
          <progress id="played" max={1} value={played} />
        </VideoControlsSeek>
      </VideoControls>
    </VideoControlsWrapper>
  )
};

VideoPlayerControls.propTypes = {
  handlePlayPause: PropTypes.func,
  playing: PropTypes.bool,
  handleToggleMuted: PropTypes.func,
  muted: PropTypes.bool,
  handleClickFullscreen: PropTypes.func,
  played: PropTypes.number,
  loaded: PropTypes.number,
  showSinopsis: PropTypes.func
}

export default VideoPlayerControls;
