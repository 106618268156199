import React from 'react';
import PropTypes from 'prop-types';
import {StaticImg} from './StyledComponents';

const StaticImgComponent = ({ alt, src, maxWidth }) => {
  return (
    <StaticImg maxWidth={maxWidth}>
      <img src={src} alt={alt} loading="lazy" width={maxWidth}/>
    </StaticImg>
  )
}

StaticImgComponent.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.any,
  maxWidth: PropTypes.any,
}

export default StaticImgComponent;
