import styled from "styled-components";

/** Functional Libraries */
import { Link } from "gatsby";
import ReactPlayer from "react-player";
import Img from "gatsby-image";

// Importing assets
import spinner from "./../assets/loader/spinner.gif";
import orientation from "./../assets/img/orientation.gif";
import { Row } from "react-flexbox-grid";

/** General Components */
export const RootLayout = styled.main`
  min-height: 100vh;
  width: 100%;
  background: var(--c400);
  position: relative;
`;

export const StaticImg = styled.figure`
  display: inline-block;
  width: 100%;
  max-width: ${props => `${props.maxWidth}px`};
  img {
    width: 100%;
    display: inline-block;
  }
`;

export const FluidImage = styled(Img)`
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    box-shadow: 0 4px 12px rgba(0,0,0,.12);
`;

export const Subtitle = styled.div`
  letter-spacing: 0;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${props => props.color};
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 4rem;
    height: 0.125rem;
    display: inline-block;
    background: #c64a3b;
    bottom: 0;
    left: ${props => props.align === "left" ? 0 : "calc(50% - 2rem)"};
  }
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${props => props.color};
`;

export const ParagraphDash = styled.p`
  font-size: 1rem;
  line-height: 1.25rem;
  position: relative;
  color: ${props => props.color};
  padding: .5rem 0;
  margin-bottom: .5rem;
  &:after {
    content: "";
    position: absolute;
    width: 4rem;
    height: 0.125rem;
    display: inline-block;
    background: #c64a3b;
    bottom: 0;
    left: 0;
  }
`;

export const ParagraphSmall = styled.small`
  font-size: 0.85rem;
  line-height: 1.25rem;
  color: ${props => props.color};
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-word;

`;

export const HeadingNotVisible = styled.h1`
  visibility: hidden;
  position: fixed;
  width: 0;
  height: 0;
  top: -999999px;
  left: -999999px;
`;

export const ButtonL = styled(Link)`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--c100);
  border: 0;
  height: 3rem;
  line-height: 3rem;
  width: 100%;
  border-radius: 3rem;
  color: var(--c400);
  transition: all var(--hover_timing) ease-in;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  margin-top: 1rem;
  &:hover {
    background: var(--c100_light);
    color: var(--c300);
  }
`;

export const ButtonSinopsis = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--c100);
  border: 0;
  height: 2.5rem;
  padding: 0 1rem 0 0.5rem;
  border-radius: 0 2rem 2rem 0;
  color: var(--c400);
  transition: all 0.15s ease-in;
  display: inline-block;
  text-align: center;
  margin-right: 0.5rem;
  cursor: pointer;
  &:hover {
    background: var(--c100_light);
  }
`;

export const ButtonText = styled.button`
  color: var(--c100);
  -webkit-transform: rotate(-90deg) translateX(-35px);
    transform: rotate(-90deg) translateX(-35px);
    display: inline-block;
    width: 6rem;
    text-decoration: none;
    transition: color .15s ease-in;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    padding: 0;
    text-align: right;
    cursor: pointer;
`;

export const DialogCloseButton = styled.button`
  position: absolute;
  appearance: none;
  top: 1rem;
  right: 0;
  background: var(--c100);
  width: 3rem;
  height: 3rem;
  border: 0;
  border-radius: 3rem 0 0 3rem;
  padding: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c400);
`;

export const DialogWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--c200) url(${props => props.backgroundMob}) no-repeat;
  background-size: cover;
  z-index: -9;
  transition: all 250ms ease-in;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  @media screen and (min-width: 1000px) {
    background: var(--c200) url(${props => props.backgroundDesk}) no-repeat;
    background-size: cover;
  }
  &.active {
    z-index: 999;
    visibility: visible;
    opacity: 1;
  }
`;

/** Landing Page */
export const LandingWrapper = styled.section`
  min-height: 100vh;
  width: 100%;
  background: url(${props => props.background}) no-repeat center bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LandingContainer = styled.section`
  max-width: 380px;
  width: 100%;
  text-align: center;
  @media screen and (min-width: 1000px) {
    max-width: 420px;
    width: 100%;
  }
`;

export const LandingSocialShare = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 3rem;
  background-color: var(--c100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`;

export const LandingSocialShareLine = styled.span`
  background: var(--c400);
  display: inline-block;
  height: 0.125rem;
  width: 1.5rem;
  margin: 0 0.5rem;
`;

export const LandingSocialShareLink = styled.a`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--c400);
  margin-right: 0.5rem;
  transition: all ease-in 150ms;
  &:hover {
    border-radius: 2rem;
    background: var(--c100_dark);
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const HomeSocialShareLink = styled.a`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--c100);
  margin-bottom: .5rem;
  transition: all ease-in 150ms;
  &:hover {
    border-radius: 2rem;
    background: var(--c200);
  }
  &:last-child {
    margin-right: 0;
    margin-bottom: 0rem;
  }
`;


/** Video Player */
export const VideoWrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  background: var(--c300_dark) url(${spinner}) no-repeat center center;
  background-size: 40px 40px;
  position: relative;
`;

export const VideoPlayerCanvas = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;
export const VideoControlsWrapper = styled.section`
  position: absolute;
  bottom: 1rem;
  left: 0;
  display: flex;
  align-items: center;
`;
export const VideoControls = styled.section`
  padding: 1rem;
  background: var(--c200_light);
  height: 2.5rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
`;

export const MobilePlay = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4rem;
  height: 4rem;
  transform: translate(-50%, -50%);
  z-index: 1;
  appearance: none;
  background: var(--c400);
  border-radius: 3rem;
  cursor: pointer;
  color: var(--c100);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all ease-in 150ms;
  opacity: 1;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  &:hover {
    background: var(--c100);
    color: var(--c400);
  }
`;

export const VideoControlsButton = styled.button`
  appearance: none;
  border: 0;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--c100);
  margin-right: 0.25rem;
  transition: all ease-in 150ms;
  background: var(--c200_light);
  &:hover {
    border-radius: 2rem;
    background: var(--c200);
  }
`;

export const VideoControlsSeek = styled.section`
  position: relative;
  width: 6rem;

  #played,
  #loaded {
    position: absolute;
    top: calc(50% - 3px);
    width: 100%;
    height: 6px;
    -webkit-appearance: none;
    appearance: none;
  }
  #loaded {
    &::-webkit-progress-bar {
      background-color: var(--c200);
      border-radius: 2px;
    }
    &::-webkit-progress-value {
      background-color: var(--c200_dark);
      border-radius: 2px;
    }
  }
  #played {
    &::-webkit-progress-bar {
      background-color: transparent;
      border-radius: 2px;
    }
    &::-webkit-progress-value {
      background-color: var(--c100);
      border-radius: 2px;
    }
  }
`;

// VideoGallery Components
export const VideoGalleryNav = styled.nav`
  position: fixed;
  background: var(--c400);
  height: 100%;
  width: 3.5rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  transition: all 0.5s ease-in;
  transform: translate3d(0, 0, 0);
  &.isOpen {
    right: calc(100% - 3.5rem);
  }
`;

export const VideoGalleryOpen = styled.button`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: calc(50% + .5rem);
  left: -2rem;
  background: var(--c400);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  color: var(--c100);
  transition: all var(--hover_timing) ease-in;
  z-index: 2;
  outline: 0;
  cursor: pointer;
  &:hover {
    color: var(--c400);
    background: var(--c100);
  }
  &.isOpen {
    left: 1.5rem;
  }
`;

export const CasaArtesanias = styled.a`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: calc(50% - 4.5rem);
  left: -2rem;
  background: var(--c400);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  color: var(--c100);
  transition: all var(--hover_timing) ease-in;
  z-index: 2;
  outline: 0;
  cursor: pointer;
  border: 4px solid var(--c400);
  &:hover {
    border: 4px solid var(--c100);
  }
  &.isOpen {
    left: 1.5rem;
  }
`;

export const VideoGalleryContainer = styled.section`
  background: var(--c200) url(${props => props.backgroundMob}) no-repeat;
  background-size: cover;
  position: fixed;
  z-index: 2;
  width: calc(100% - 3.5rem);
  height: 100%;
  top: 0;
  right: calc(-100% + 3.5rem);
  background-size: cover;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in;
  transform: translate3d(0, 0, 0);
  @media screen and (min-width: 1000px) {
    background: var(--c200) url(${props => props.backgroundDesk}) no-repeat;
    background-size: cover;
  }
  &.isOpen {
    padding-left: 3rem;
    right: 0rem;
  }
`;


export const VideoGalleryItem = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  text-decoration: none;
  margin-right: 1rem;
  color: var(--c300_light);
  margin-top: 1rem;
  padding-bottom: 1.5rem;
`;

export const VideoGalleryImage = styled.figure`
  position: relative;
  width: 100%;
  display: block;
`;

export const VideoGalleryCarousel = styled(Row)`
  flex-wrap: nowrap;
  overflow-x: auto;
`;

export const VideoGalleryBadge = styled.small`
  background: var(--c100);
  color: var(--c400);
  padding: .25rem .5rem;
  border-radius: 1rem;
  position: absolute;
  bottom: .5rem;
  left: .5rem;
  font-size: .9rem;
  letter-spacing: .5px;
`;

export const SponsorsContainer = styled(Row)`
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;
`;

export const Orientation = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--c100_dark) url(${orientation})no-repeat;
  background-size: 180px;
  background-position: center center;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 14rem;
  }
`;
