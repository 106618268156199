import React, { useState, Fragment } from "react";
/** Importing GraphQL Functions */
import { useStaticQuery, graphql, Link } from "gatsby";
import StaticImgComponent from "./StaticImg";

import PropTypes from "prop-types";

import {
  VideoGalleryNav,
  VideoGalleryOpen,
  VideoGalleryContainer,
  VideoGalleryItem,
  Subtitle,
  FluidImage,
  ParagraphDash,
  ParagraphSmall,
  VideoGalleryCarousel,
  VideoGalleryImage,
  VideoGalleryBadge,
  ButtonText,
  Paragraph,
  HomeSocialShareLink,
  CasaArtesanias
} from "./StyledComponents";
import { Film, X, Instagram, Facebook } from "react-feather";
import {Grid, Row, Col} from 'react-flexbox-grid';
import { D } from "../constants/dictionary";
import AboutUs from "./AboutUs";

const ARTESANIAS = require('./../assets/img/logo-artesanias.png');

const VideoGalleryCard = ({data}) => {
  return (
    <VideoGalleryItem to={data.fields.slug}>
      <Link style={{textDecoration: "none"}} to={data.fields.slug}>
        <VideoGalleryImage>
          <FluidImage
            loading='eager'
            fluid={data.frontmatter.videoImage.image.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={data.frontmatter.title}
          />
          {data.frontmatter.new && <VideoGalleryBadge>{D.video.new}</VideoGalleryBadge>}
        </VideoGalleryImage>
        <ParagraphDash color="var(--c300_light)">{data.frontmatter.title}</ParagraphDash>
        <ParagraphSmall color="var(--c300_light)">{data.frontmatter.campaignSummary}</ParagraphSmall>
      </Link>
    </VideoGalleryItem>
  )
};

const VideoGallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [aboutUs, setAboutUs] = useState(false);

  const showAboutUs = () => {
    setAboutUs(!aboutUs);
  }


  const GALLERY_DATA = useStaticQuery(
    graphql`
      query {
        landingYaml {
          facebook
          instagram
          vimeo
        }
        homeYaml {
          sinopsisBgMov {
            image
          }
          sinopsisBgDesk {
            image
          }
        }
        getPages: allMarkdownRemark(
          filter:{fields: {contentType: {eq: "pages"}}}
          sort:{fields: frontmatter___new order: DESC}) {
          edges {
            node {
              frontmatter {
                title
                new
                campaignSummary
                videoImage {
                  image {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 75) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                        presentationHeight
                      }
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  const openGallery = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <AboutUs aboutUs={aboutUs} showAboutUs={showAboutUs}/>
      <VideoGalleryNav className={isOpen ? "isOpen" : null}>
        {!isOpen && <ButtonText onClick={showAboutUs}><Paragraph>{D.home.aboutUs}</Paragraph></ButtonText>}
        <CasaArtesanias className={isOpen ? "isOpen" : null} href="http://artesanias-michoacan.com/">
          <StaticImgComponent
            alt={D.home.sponsors}
            maxWidth={200}
            src={ARTESANIAS}
          />
        </CasaArtesanias>
        <VideoGalleryOpen
          className={isOpen ? "isOpen" : null}
          onClick={openGallery}
        >
          {!isOpen ? <Film size={32} /> : <X size={32} />}
        </VideoGalleryOpen>

        <section>
          {!isOpen && <HomeSocialShareLink aria-label="Enlace para instagram" href={GALLERY_DATA.landingYaml.instagram} target="_blank" rel="noopener norefferer"><Instagram size={28} /></HomeSocialShareLink>}
          {!isOpen && <HomeSocialShareLink aria-label="Enlace para facebook" href={GALLERY_DATA.landingYaml.facebook} target="_blank" rel="noopener norefferer"><Facebook size={28} /></HomeSocialShareLink>}
        </section>
      </VideoGalleryNav>
      <VideoGalleryContainer
        backgroundMob={GALLERY_DATA.homeYaml.sinopsisBgMov.image}
        backgroundDesk={GALLERY_DATA.homeYaml.sinopsisBgDesk.image} className={isOpen ? "isOpen" : null}>
        <Grid fluid style={{width: "100%"}}>
          <Row><Subtitle align="left" color="var(--c300_light)">{D.home.documentals}</Subtitle></Row>
          <VideoGalleryCarousel>
            {GALLERY_DATA.getPages.edges.map((el, i) => {
              return (
                <Col key={i} xs={5} md={3}>
                  <VideoGalleryCard
                    data={el.node}></VideoGalleryCard>
                </Col>
              )
            })}
          </VideoGalleryCarousel>
        </Grid>
      </VideoGalleryContainer>
    </Fragment>
  );
};

VideoGalleryCard.propTypes = {
  data: PropTypes.object,
}
export default VideoGallery;
