import React, {Component} from 'react';
import {
  isMobile,
  isSafari
} from "react-device-detect";
import PropTypes from 'prop-types';
//  Adding styled components
import { VideoWrapper, VideoPlayerCanvas, MobilePlay } from './StyledComponents';
import VideoPlayerControls from './VideoPlayerControls';
import { PlayCircle } from 'react-feather';
// Requiring screenfull
const screenfull = require('screenfull');
// Using helper functions


class VideoPlayerComponent extends Component {
  constructor(props) {
    super(props);
    this.state ={
      url: `https://vimeo.com/${props.videoID}`,
      pip: false,
      playing: isMobile ? false : isSafari ? false : true,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      ended: false
    }
  }

  load = url => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false
    })
  }

  // Setting ref data
  videoRef = player => {
    this.player = player
  }

  // Adding function to handle play/pause event
  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  // Functionality to handle mute
  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted })
  }

  // Functionality to go fullscreen
  handleClickFullscreen = () => {
    screenfull.request(this.player)
  }

  // Check Progress
  handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
    }
  }

  handleEnded = () => {
    console.log('onEnded')
    this.setState({ ended: true, playing: false })
  }

  // TO.DO = Add validation and modal to show when video has finished
  // Change state ended when user play again


  render() {
    // Defragmenting constants
    const { url, playing, controls, light, volume, muted, loop, played, loaded, ended, playbackRate, pip } = this.state
    return (
      <VideoWrapper>
        {!playing && <MobilePlay onClick={this.handlePlayPause}><PlayCircle size={42}/></MobilePlay> }
        <VideoPlayerCanvas
          ref={videoRef => this.videoRef = videoRef}
          className="react-player"
          width="100vw"
          height="100vh"
          url={url}
          pip={pip}
          playing={playing}
          controls={controls}
          light={light}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onPlay={this.handlePlay}
          onPause={this.handlePause}
          onEnded={this.props.handleEnded}
          onError={e => console.log('onError', e)}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
        />
        <VideoPlayerControls
          playing={playing}
          handlePlayPause={this.handlePlayPause}
          muted={muted}
          handleToggleMuted={this.handleToggleMuted}
          played={played}
          handleClickFullscreen={this.handleClickFullscreen}
          loaded={loaded}
          showSinopsis={this.props.showSinopsis}
        />
      </VideoWrapper>
    )
  }
}

VideoPlayerComponent.propTypes = {
  videoID: PropTypes.string,
  showSinopsis: PropTypes.func,
  handleEnded: PropTypes.func,
}

export default VideoPlayerComponent;

